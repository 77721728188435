import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const ImageHtml = ({contentType, cssClass, innerHtml}) => {
    const [image, setImage] = useState()
    const [imageWidth, setImageWidth] = useState(window.innerWidth)
    const [imageHeight, setImageHeight] = useState(window.innerHeight)
    const [altText, setAltText] = useState('')
    const [link, setAltLink] = useState('')
    const [actualHeight, setActualHeight] = useState(0)

    
    useEffect(()=>{
        if(contentType){
            var imageVal = contentType.values.filter((value) => value.settingAlias === "image")[0]
            if(imageVal){
                var imageStr = imageVal.contentPublished
                if(imageVal.media){
                    imageStr = "https://images.contentcloud.dk/gsassets/" 
                             + global.config.Editor.publicToken 
                             + imageVal.media.contentMediaUrl 
                             + '?format=webp&width=' + (window.innerWidth < 720 ? 720 : window.innerWidth)
                             + (window.innerWidth <= 720 ? '&quality=80' : '')
                    setImageWidth(imageVal.media.contentWidth)
                    setImageHeight(imageVal.media.contentHeight)
                }
                setImage(imageStr)
            }
            var altTextVal = contentType.values.filter((value) => value.settingAlias === "altTxt")[0]
            setAltText(altTextVal && altTextVal.contentPublished)
            var linkVal = contentType.values.filter((value) => value.settingAlias === "link")[0]
            if(linkVal){
                if(linkVal.relations.length > 0){
                    let docUrl = (linkVal.relations[0].contentModel.isArea ? '/' + linkVal.relations[0].contentModel.modelUrl : '') + '/' + linkVal.relations[0].contentTypeUrl
                    setAltLink(docUrl)
                }else{
                    setAltLink(linkVal.contentPublished)
                }
            }
        }
    },[contentType])

    let content

    if(image){
        content = (
            <>
            <Helmet>
                <link rel="prefetch" href={image} as="image" />
            </Helmet>
            {innerHtml ?
            
            <figure>                
                {(link !== '') ?
                    <Link key={contentType.id} className={cssClass} to={link}>
                        <picture>
                            <img src={image} width={imageWidth} height={imageHeight} alt={altText}/>
                        </picture>
                    </Link>
                :
                <img key={contentType.id} src={image} alt={altText}/>                
                }
            </figure>
            :
            <article key={contentType.id} className={`col-1`}>
                <div className="item image">
                    <figure>
                        {(link !== '') ?
                            <Link to={link}>
                                <picture>
                                    <img src={image} width={imageWidth} height={imageHeight} alt={altText}/>
                                </picture>
                            </Link>
                        :
                        <img src={image} width={imageWidth} height={imageHeight} alt={altText}/>
                        }
                    </figure>
                </div>
            </article>
            }
            </>
        )
    }

    return (
        <>
            {content}
        </>
    )
}

export default ImageHtml
