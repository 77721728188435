import {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'

const ArticleHtml = ({contentType}) => {
    const [image, setImage] = useState('')
    const [imageWidth, setImageWidth] = useState(window.innerWidth)
    const [imageHeight, setImageHeight] = useState(window.innerHeight)
    const [altText, setAltText] = useState('')
    const [contentText, setContentText] = useState('')

    
    useEffect(()=>{
        if(contentType){
            var imageVal = contentType.values.filter((value) => value.settingAlias === "desktopImage")[0]
            if(imageVal){
                var imageStr = imageVal.contentPublished
                if(imageVal.media){
                    imageStr = "https://images.contentcloud.dk/gsassets/" 
                             + global.config.Editor.publicToken 
                             + imageVal.media.contentMediaUrl 
                             + '?format=webp&width=' + (window.innerWidth < 720 ? 720 : window.innerWidth)
                             + (window.innerWidth <= 720 ? '&quality=80' : '')
                    setImageWidth(imageVal.media.contentWidth)
                    setImageHeight(imageVal.media.contentHeight)
                }
                setImage(imageStr)
            }
            //setImage(imageVal && imageVal.contentPublished)
            var altTextVal = contentType.values.filter((value) => value.settingAlias === "altTxt")[0]
            setAltText(altTextVal && altTextVal.contentPublished)
            var contentTextVal = contentType.values.filter((value) => value.settingAlias === "content")[0]
            setContentText(contentTextVal && contentTextVal.contentPublished)
        }
    },[contentType])

    let content

    if(contentType){
        console.log(contentType)
        content = (
            <>
            <Helmet>
                <link rel="prefetch" href={image} />
            </Helmet>
            <article key={`article-heading-${contentType.id}`} className={`col-1 image-rte`}>
                <div className="item heading">
                    <h1>{contentType.contentTypeName}</h1>
                </div>
            </article>
            <article key={`article-content-${contentType.id}`} className={`col-1 image-rte`}>
                <div className="item image">
                    <div className="inner">
                        <img src={image} width={imageWidth} height={imageHeight} alt={altText}/>
                    </div>
                </div>
                <div className="item rte">
                    <div className="inner" dangerouslySetInnerHTML={{__html: contentText}} />
                </div>
            </article>
            </>
        )
    }

    return (
        <>
           {content} 
        </>
    )
}

export default ArticleHtml
