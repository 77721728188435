import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const SliderItemHtml = ({contentType, handleHeight}) => {
    const [image, setImage] = useState('')
    const [imageWidth, setImageWidth] = useState(window.innerWidth)
    const [imageHeight, setImageHeight] = useState(window.innerHeight)
    const [altText, setAltText] = useState('')
    const [link, setAltLink] = useState('')
    const [actualHeight, setActualHeight] = useState(0)
    
    
    useEffect(()=>{
        if(contentType){
            var imageVal = contentType.values.filter((value) => value.settingAlias === "image")[0]
            if(imageVal){
                var imageStr = imageVal.contentPublished
                if(imageVal.media){
                    imageStr = "https://images.contentcloud.dk/gsassets/" 
                             + global.config.Editor.publicToken 
                             + imageVal.media.contentMediaUrl 
                             + '?format=webp&width=' + (window.innerWidth < 720 ? 720 : window.innerWidth)
                             + (window.innerWidth <= 720 ? '&quality=80' : '')
                    setImageWidth(imageVal.media.contentWidth)
                    setImageHeight(imageVal.media.contentHeight)
                    setActualHeight((imageVal.media.contentHeight / imageVal.media.contentWidth) * window.innerWidth)
                    handleHeight((imageVal.media.contentHeight / imageVal.media.contentWidth) * window.innerWidth)
                }
                setImage(imageStr)
            }
            //setImage(imageVal && imageVal.contentPublished)
            var altTextVal = contentType.values.filter((value) => value.settingAlias === "altTxt")[0]
            setAltText(altTextVal && altTextVal.contentPublished)
            var linkVal = contentType.values.filter((value) => value.settingAlias === "link")[0]
            if(linkVal){
                if(linkVal.relations.length > 0){
                    let docUrl = (linkVal.relations[0].contentModel.isArea ? '/' + linkVal.relations[0].contentModel.modelUrl : '') + '/' + linkVal.relations[0].contentTypeUrl
                    setAltLink(docUrl)
                }else{
                    setAltLink(linkVal.contentPublished)
                }
            }
        }
    },[contentType])


    let content

    if(image){
        content = (
            <>
            <Helmet>
                <link rel="prefetch" href={image} as="image" />
            </Helmet>
            <div key={contentType.id} className="carousel-item" style={{height:actualHeight}} >
                {(link !== '') ?
                    (link.includes('http')) ?
                    <a href={link}>
                        <img src={image} width={imageWidth} height={imageHeight} alt={altText}/>
                    </a>
                    :
                    <Link to={link}>
                        <img src={image} width={imageWidth} height={imageHeight} alt={altText}/>
                    </Link>
                :
                <img src={image} width={imageWidth} height={imageHeight} alt={altText}/>
                }
                
            </div>
            </>
        )
    }

    return (
        <>
           {content} 
        </>
    )
}

export default SliderItemHtml
