import { act } from '@testing-library/react'
import {useState, useEffect, useRef} from 'react'
import { Helmet } from 'react-helmet'

const ImageWithTextHtml = ({contentType}) => {
    const [image, setImage] = useState('')
    const [imageWidth, setImageWidth] = useState(window.innerWidth)
    const [imageHeight, setImageHeight] = useState(window.innerHeight)
    const [altText, setAltText] = useState('')
    const [contentText, setContentText] = useState('')
    const [actualHeight, setActualHeight] = useState(0)
    const refImage = useRef(null);
    //const [textHeight, setTextHeight] = useState(0)
    const refText = useRef(null);
    //const [articleHeight, setArticleHeight] = useState(0)
    const refArticle = useRef(null);

    
    useEffect(()=>{
        if(contentType){
            var imageVal = contentType.values.filter((value) => value.settingAlias === "image")[0]
            if(imageVal){
                var imageStr = imageVal.contentPublished
                if(imageVal.media){
                    imageStr = "https://images.contentcloud.dk/gsassets/" 
                             + global.config.Editor.publicToken 
                             + imageVal.media.contentMediaUrl 
                             + '?format=webp&width=' + (window.innerWidth < 720 ? 720 : window.innerWidth)
                             + (window.innerWidth <= 720 ? '&quality=80' : '')
                    setImageWidth(imageVal.media.contentWidth)
                    setImageHeight(imageVal.media.contentHeight)
                    
                    setActualHeight((imageVal.media.contentHeight / imageVal.media.contentWidth) * refImage.current.offsetWidth)
                    
                }
                setImage(imageStr)
            }
            //setImage(imageVal && imageVal.contentPublished)
            var altTextVal = contentType.values.filter((value) => value.settingAlias === "altTxt")[0]
            setAltText(altTextVal && altTextVal.contentPublished)
            var contentTextVal = contentType.values.filter((value) => value.settingAlias === "content")[0]
            setContentText(contentTextVal && contentTextVal.contentPublished)
        }
    },[contentType])

    useEffect(() => {
        //setTextHeight(refText.current.clientHeight)
        
        //setArticleHeight((window.innerWidth <= 768) ? refText.current.clientHeight + actualHeight : (actualHeight >= refText.current.clientHeight ? actualHeight : refText.current.clientHeight))
      },[refText.current])


    let content

    //if(image){
        content = (
            <>
            <article key={contentType.id} className={`col-1 image-rte`} >
                <div className="item rte">
                    <div className="inner" dangerouslySetInnerHTML={{__html: contentText}}  ref={refText}/>
                </div>
                <div className="item image" ref={refImage}>                    
                    <figure>
                        <picture>
                            <img  src={image} width={imageWidth} height={imageHeight} alt={altText}/>
                        </picture>
                    </figure>
                </div>
            </article>
            </>
        )
    //}

    return (
        <>
            <Helmet>
                <link rel="preload" href={image} as="image" />
            </Helmet>
            {content}
        </>
    )
}

export default ImageWithTextHtml
